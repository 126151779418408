import { ButtonProps } from '~/components/interactive/Button';
import LoadingButton from '~/components/interactive/LoadingButton';
import { useIsValibotFormValid } from '~/components/valibot/ValibotForm';

export default function ValibotButton({
  showError,
  disabled,
  ...props
}: Omit<ButtonProps, 'type'> & {
  showError?: boolean;
}) {
  const valid = useIsValibotFormValid();

  const button = <LoadingButton type="submit" {...props} disabled={valid !== true || disabled} />;

  if (!showError) {
    return button;
  }

  return (
    <div className="inline-flex items-center space-x-2">
      {button}
      {valid !== true && <div className="text-xs text-theme-light">{valid}</div>}
    </div>
  );
}
